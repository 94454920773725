import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AppStoreLinks from '@/components/AppStoreLinks/AppStoreLinks';
import CloseIcon from '@/components/icons/close.svg';
import PatreonWordmark from '@/components/icons/patreon-wordmark.svg';
import PlusIcon from '@/components/icons/plus.svg';

import useHomeLinkProps from '@/hooks/useHomeLinkProps';
import type { CallToActionButton } from '@/utilities/strapi/types/componentTypes';
import type { NavigationGroup } from '@/utilities/strapi/types/utilsTypes';

import MobileSubLevelMenu from './MobileSubLevelMenu';
import CTAButton from '../Blocks/CTAButton';
import Button from '../Elements/Buttons/Button';
import StrapiLink from '../utils/StrapiLink';

interface MobileMenuProps {
  navigationGroups: NavigationGroup[];
  closeCallback: () => void;
  isOpen: boolean;
  getStartedButton?: CallToActionButton;
  updatesButton?: CallToActionButton;
}

export default function MobileMenu({
  navigationGroups,
  closeCallback,
  isOpen,
  getStartedButton,
  updatesButton,
}: MobileMenuProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const navList = useRef<HTMLUListElement>(null);
  const [activeMenuId, setActiveMenuId] = useState<number | null>(null);

  const homeLinkProps = useHomeLinkProps();

  function backCallback() {
    setActiveMenuId(null);
    navList.current?.focus();
  }

  useEffect(() => {
    const { current: el } = dialogRef;
    if (el && !el.open && isOpen) {
      el.showModal();
    }
    if (isOpen) {
      navList.current?.focus();
    }
  }, [isOpen]);

  const onCancel = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      closeCallback();
    },
    [closeCallback],
  );

  const onTransitionEnd = useCallback(() => {
    const { current: el } = dialogRef;

    if (el && !isOpen) {
      el.close();
    }
  }, [isOpen, dialogRef]);

  return (
    <StyledMobileMenu
      className={clsx({ open: isOpen })}
      tabIndex={-1}
      ref={dialogRef}
      onCancel={onCancel}
      onTransitionEnd={onTransitionEnd}
    >
      <nav>
        <section className="level-1" inert={isOpen && !activeMenuId ? undefined : ''}>
          <header className="nav-section-header">
            <div className="search" />
            <div className="brand">
              <StrapiLink {...homeLinkProps} className="logo-link">
                <span className="sr-only">Patreon</span>
                <PatreonWordmark className="wordmark" />
              </StrapiLink>
            </div>
            <div className="close">
              <Button icon={<CloseIcon />} onClick={closeCallback} label="Close" hideLabel size="sm" />
            </div>
          </header>

          <div className="nav-wrapper" data-lenis-prevent>
            <ul ref={navList} className="nav-list" tabIndex={-1}>
              {navigationGroups.map((group) => (
                <li className="nav-item" key={group.id}>
                  {group.titleLink?.page || group.titleLink?.externalUrl ? (
                    <StrapiLink {...group.titleLink} text={group?.title} className="nav-button level-1-link" />
                  ) : (
                    <Button
                      childrenLabelOrder="childrenLast"
                      label={group.title ?? group.titleLink?.text}
                      className={clsx('nav-button', { 'has-submenu': group.linksWithAnchors.length > 0 })}
                      onClick={() => {
                        if (group.linksWithAnchors.length > 0) {
                          setActiveMenuId(group.id);
                        }
                      }}
                    >
                      {!group.titleLink?.text && <StyledPlusIcon />}
                    </Button>
                  )}
                </li>
              ))}
              {updatesButton && (
                <li>
                  <StrapiLink
                    {...updatesButton}
                    externalUrl={updatesButton?.external_url}
                    text={updatesButton.label}
                    className="nav-button level-1-link emphasize"
                  />
                </li>
              )}
            </ul>
          </div>

          <footer className="nav-section-footer">
            {getStartedButton && (
              <CTAButton
                label={getStartedButton.label}
                page={getStartedButton.page}
                external_url={getStartedButton.external_url}
                genericClickDetails={getStartedButton.genericClickDetails}
                className="full-width medium get-started-button"
              />
            )}
            <div className="apps-store-wrapper">
              <AppStoreLinks className="app-store-button" />
            </div>
          </footer>
        </section>

        {navigationGroups.map((group) => (
          <MobileSubLevelMenu
            key={group.id}
            navigationGroup={group}
            activeMenuId={activeMenuId}
            closeCallback={closeCallback}
            backCallback={backCallback}
          />
        ))}
      </nav>
    </StyledMobileMenu>
  );
}

const StyledMobileMenu = styled.dialog`
  color: var(--color-action-contrast);
  width: 100%;
  height: 100vh;
  height: 100dvh;
  max-width: none; // override the user-agent default for <dialog>
  max-height: none;
  background-color: var(--color-action-default);
  position: fixed;
  top: 0px;
  transform: translateX(-100%);
  transition: transform var(--animation-duration-fast) var(--animation-timing);
  z-index: 10;
  display: block;

  padding: 0;
  margin: 0;

  @media (min-width: 769px) {
    display: none;
  }

  &.open {
    transform: translateX(0px);
  }

  > nav {
    height: 100%;
  }

  .level-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 0 1 0;
  }

  .nav-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-xs);

    @media (orientation: landscape) {
      padding: 0;
    }
  }

  .search {
    width: 6.8rem;
    height: 5.6rem;
  }

  .brand {
    width: 16.2rem;
    height: 3.8rem;
    min-height: 38px;

    .logo-link {
      height: 3.8rem;
    }
  }

  .wordmark {
    display: block !important;
    object-fit: contain;

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }

  .nav-wrapper {
    height: 100%;
    overflow-y: auto;
  }

  .nav-list {
    padding: var(--spacing-sm);

    &:focus-visible {
      outline: none;
    }
  }

  .nav-button {
    font: var(--font-sub-h1-light);
    margin-bottom: 1.8rem;
    padding: 0;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    margin-left: -0.3rem;
    letter-spacing: -0.06rem;

    .label {
      margin-left: 0;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .emphasize {
    margin-top: 1rem;
    border: 1px solid var(--color-action-contrast);
    padding: 2rem 2.8rem;
    border-radius: 4rem;
    width: fit-content;
  }

  .cta-button {
    margin-top: var(--spacing-sm);
  }

  .get-started-button {
    background-color: var(--color-action-contrast);
    border-color: var(--color-action-contrast);
    color: var(--color-action-default);
  }

  .nav-section-footer {
    padding: var(--spacing-sm);

    @media (orientation: landscape) {
      padding: var(--spacing-xs) var(--spacing-sm);
      margin: 0 auto;
      width: 100%;
      max-width: 320px;

      .get-started-button {
        padding: var(--spacing-xs) var(--spacing-sm);
      }
    }
  }

  .apps-store-wrapper {
    display: flex;
    gap: var(--spacing-sm);
    margin: var(--spacing-base) 0 var(--spacing-sm);

    @media (orientation: landscape) {
      gap: var(--spacing-xs);
      margin-top: var(--spacing-xs);
      margin-bottom: 0;
    }
  }

  .app-store-button {
    display: block;
    width: 100%;
    > img {
      width: 100%;
    }
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  display: block;
  width: 1.2rem;
  height: 1.2rem;
`;
